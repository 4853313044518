/* ROOT */

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Jost:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Fugaz+One&display=swap");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url("https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css");

@font-face {
  font-family: "Gilroy Heavy Italic";
  src: url("./assets/fonts/Gilroy-HeavyItalic.ttf");
}
@font-face {
  font-family: "Montserrat Black Italic";
  src: url("./assets/fonts/Montserrat-BlackItalic.otf");
}

* {
  --primary-color: #ef233c;
  --secondary-color: #1d061a;
  --primary-font: "Montserrat Black Italic";
  --secondary-font: "Gilroy Heavy Italic";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

a {
  color: red;
}
a .enlaces {
  -webkit-text-stroke: 1px red !important;
  color: transparent !important;
}

/* INICIO */

.inicio {
  height: 90vh;
}

.containerElements {
  display: flex;
  flex-direction: column;
}
.video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 40%;
  height: 120%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.titleInicio {
  font-family: "Montserrat Black Italic";
  color: #ef233c;
  letter-spacing: 0.1rem;
  font-size: 4rem;
  display: block;
  white-space: nowrap;
  border-right: 4px solid;
  width: 8.5ch;
  animation: typing 2s steps(8), blink 0.5s infinite step-end alternate;
  overflow: hidden;
}
@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@media (max-width: 460px) {
  .titleInicio {
    font-size: 2.5rem;
  }
  .parrafoHome {
    font-size: .3rem;
  }
}
.spanHome {
  display: flex;
  justify-content: center;
}
i {
  color: #fee600;
  font-size: 4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;

  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 2;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 2;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 2;
  }
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: rgb(36, 36, 36);
  background-color: var(--secondary-color) !important;
  font-family: var(--primary-font);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(315deg, #ffbc00, #ff0058);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.btnInicio {
  background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%);
  padding: 0.5rem;
  border-radius: 1rem;
  border-style: none;
  cursor: pointer;
  font-family: var(--primary-font);
}
.containerBtn {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
a {
  text-decoration: none;
}
.parrafoHome {
  font-family: "Gilroy Heavy Italic";
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
  -webkit-text-stroke: 1px red;
  color: transparent;
}

/* Main */

.bodyMain {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d061a;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
}

.container .box {
  position: relative;
  width: 320px;
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.container .box::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.container .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.container .box:hover:before,
.container .box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.container .box:nth-child(1):before,
.container .box:nth-child(1):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}

.container .box:nth-child(2):before,
.container .box:nth-child(2):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}

.container .box:nth-child(3):before,
.container .box:nth-child(3):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}
.container .box:nth-child(4):before,
.container .box:nth-child(4):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.container .box:nth-child(5):before,
.container .box:nth-child(5):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}
.container .box:nth-child(6):before,
.container .box:nth-child(6):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}
.container .box:nth-child(7):before,
.container .box:nth-child(7):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}
.container .box:nth-child(8):before,
.container .box:nth-child(8):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.container .box:nth-child(9):before,
.container .box:nth-child(9):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}
.container .box:nth-child(10):before,
.container .box:nth-child(10):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.container .box:nth-child(11):before,
.container .box:nth-child(11):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}
.container .box:nth-child(12):before,
.container .box:nth-child(12):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}

.container .box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.container .box span::before {
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  transition: 0.1s;
  position: absolute;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.container .box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.container .box span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.container .box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.container .box .content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
  word-spacing: 2px;
  letter-spacing: 1px;
}

.container .box:hover .content {
  left: -25px;
  padding: 60px 40px;
}

.container .box .content h2 {
  font-size: 2em;
  color: black;
  margin-bottom: 10px;
  font-family: var(--primary-font);
}

.container .box .content p {
  font-size: 1em;
  margin-bottom: 10px;
  line-height: 1.4em;
  font-family: var(--secondary-font);
  text-transform: uppercase;
}

.container .box .content a {
  display: inline-block;
  font-size: 1.1em;
  font-family: var(--secondary-font);
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.container .box .content a:hover {
  background: black;
  -webkit-text-stroke: 1px red;
  color: transparent;
  letter-spacing: 2px;
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}

.criptoDoge {
  width: 7rem;
}
.criptoBTC {
  width: 8rem;
}
.criptoETH {
  width: 7rem;
}
.criptoMana {
  width: 8rem;
}
.criptoETC {
  width: 7rem;
}
.criptoDai {
  width: 7.5rem;
}
.criptoXRP {
  width: 4rem;
}
.criptoXRP2 {
  width: 3rem;
}
.criptoLTC {
  width: 3.5rem;
}
.criptoLTC2 {
  width: 3.5rem;
  margin-bottom: 1.5rem;
}
.criptoADA2 {
  width: 7rem;
  margin-bottom: 1.5rem;
}
.criptoDOT {
  width: 3.5rem;
}
.criptoDOT2 {
  width: 3rem;
  margin-bottom: 1.5rem;
}
.criptoBNB {
  width: 3.5rem;
}
.criptoBNB2 {
  width: 3.5rem;
  margin-bottom: 1.3rem;
}
.criptoUBI {
  width: 3.5rem;
}
.criptoUBI2 {
  width: 3.5rem;
  margin-bottom: 1.5rem;
}

/* CRYPTO CURRENCY */

.dashboard-container {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerDai {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerBTC {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerClassic {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerMana {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerDoge {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerBNB {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerADA {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerLTC {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerDOT {
  background-color: #c2066a;
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerXRP {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}
.dashboard-containerUBI {
  background-image: url("./assets/Black\ and\ noise.jpg");
  display: flex;
  flex-direction: column;
}

.titleCriptos {
  
  font-family: var(--primary-font);
  padding: 1rem;
  text-align: center;
  color: whitesmoke;
}
.price {
  color: var(--primary-color);
}
#last-price {
  color: rgb(255, 255, 255);
  font-family: var(--primary-font);
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.containerChart {
  background-color: transparent;
  padding: 1rem;
}
.infoCriptos {
  color: rgb(0, 0, 0);
}
.infoCriptos h2 {
  font-family: var(--primary-font);
  -webkit-text-stroke: 1px red;
  color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 2rem;
}
.infoCriptos h3 {
  font-family: var(--primary-font);
  -webkit-text-stroke: 1px red;
  color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.9rem;
}
li {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.infoCriptos p {
  font-family:var(--primary-font);
  font-weight: bold;
  text-transform: uppercase;
  font-size: .8rem;
  color: whitesmoke;

}
span {
  color: var(--primary-color);
  font-style: italic;
}
.containerText {
  padding: 2rem;
}
.criptoETH2 {
  width: 7rem;
  display: flex;
  align-content: center;
}
.containerCriptoETH {
  display: flex;
  justify-content: center;
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(-180deg);
  }
}
.epigrafe {
  text-align: start;
  font-style: italic;
  font-size: .6rem;
  margin-left: 2rem;
  margin-bottom: -0.6rem;
  text-indent: 0;
  color: rgba(167, 167, 167, 0.701);
}
.epigrafe a {
  text-decoration: none !important;
}
.videos {  
  height: 0;
	overflow: hidden;
	padding-bottom: 30%;
	padding-top: 30px;
	position: relative;
}
.videos iframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	}
  .video-container {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
.containerPDF {
display: flex;
flex-direction: column;
align-items: center;
height: 90vh;
  }
.back {
  display: inline-block;
  font-size: 1em;
  font-family: var(--secondary-font);
  color: rgb(0, 0, 0);
  letter-spacing: 2px;
  background: rgb(255, 255, 255) !important;
  padding: 8px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 30px;
  text-transform: uppercase;
}
.back:hover {
  background: rgb(0, 0, 0) !important;
  -webkit-text-stroke: 1px red;
  color: transparent;
  letter-spacing: 2px;
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
summary {
  font-family: var(--primary-font);
  color: var(--primary-color);
  text-transform: uppercase;
}
summary:hover {
  cursor:help;
}
.formText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.parrafoForm {
  padding: 1rem;
  width: 50%;
  height: 80%;
}

/* Footer */



footer {
  background-color: black;
  padding: 0.5rem;
}

.containerFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prefooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


/*  Enviado  */

.titleEnviado {
  font-family: var(--secondary-font);
  text-transform: uppercase;
  -webkit-text-stroke: .5px red;
  color: transparent;
  padding: 1rem;
  margin-top: 7rem;
  font-size: 2.5rem;
  margin-left: 2rem;
  text-align: center;
  height: 50vh;
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 1.5rem;
    -webkit-text-stroke: 1px red;
  }  
}